.App {
  text-align: center;
}
/*.desc, .text p si .footer p avenir*/
@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
body{
  background: #1f1f21;
  color: #FFAA00;
  font-family: "Signika"!important;
}
nav a{
  color: #FFAA00BF;
  font-size: 33.6px;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
}
nav a:hover{
  color: #FFAA00;
}
nav{
  width: 85vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: auto;
}
.right img{
  /* width: 40vw; */
  width: 35vw;
}

.left .title{
  font-size: 48px;
  text-align: left;
}
.left{
  max-width: 50%;
  text-align: center;
}
.firstsec{
  width: 80vw;
  display: flex;
  justify-content: space-between;
  margin: auto;
  align-items: center;
}
@font-face {
  font-family: avenir;
  src: url(AvenirNextLTPro-Regular.otf);
}

.desc, .text p{
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  font-family: Signika;
  /* max-width: 580px; */
}
.MuiContainer-root{
  margin-top: 0px!important;
}
.MuiPaper-root{
  background: transparent!important;
  box-shadow: none!important;
}
.MuiButton-contained.Mui-disabled {
  color: rgba(0, 0, 0, 0.479)!important;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.12)!important;
}
.flexcards{
  display: flex;
  justify-content: space-between;
  width: 80vw;
  margin: auto;
}
.card{
  width: 27%;
  background: #282520;
  padding: 15px;
}
.roadmap{
  width: 60vw;
  margin: auto;
  text-align: center;
}
.thankyou{
  display: flex;
  width: 40vw;
  margin: auto;
  align-items: center;
}
footer p{
  text-align: center;
  font-size: 20px;
  font-family: Signika;
}
footer{
  padding-top: 50px;
  padding-bottom: 50px;
  border-top: 0.01em solid #ffaa002d;
}

#img-mapper { 
  margin: 0 auto;
 }
 /* .phases img{
  width: 40vw;
}  */

.horiz-menu > div {
  display: inline-block;
}

nav li {
  display: inline-block;
  font-size: 12px;
  /* padding: 20px; */
}

nav ul li a {
  font-size: 16px;
}

.flex-container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row {
  width: auto;
  /* border: 1px solid blue; */
}
.flex-item {
  /* background-color: tomato; */
  padding: 5px;
  /* width: 20px;
  height: 20px; */
  width: 300;
  height: 415px;
  margin: 10px;
  line-height: 20px;
  color: white;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
}


/* @media only screen and (min-width: 360px) and (max-width: 768px) {
	// do something in this width range.
} */

@media only screen and (max-width: 500px) {
	#one {
    right: 0% !important;
  }

  #two {
    right: 67% !important;
  }

  #three {
    left: 66% !important;
  }
}

@media only screen and (min-width: 501px) and (max-width: 525px) {
	#one {
    right: -60% !important;
  }

  #two {
    right: 49% !important;
  }

  #three {
    left: 110% !important;
  }
}

@media only screen and (min-width: 526px) and (max-width: 550px) {
	#one {
    right: -55% !important;
  }

  #two {
    right: 51% !important;
  }

  #three {
    left: 107% !important;
  }
}

@media only screen and (min-width: 551px) and (max-width: 570px) {
	#one {
    right: -50% !important;
  }

  #two {
    right: 52% !important;
  }

  #three {
    left: 105% !important;
  }
}

@media only screen and (min-width: 571px) and (max-width: 600px) {
	#one {
    right: 0% !important;
  }

  #two {
    right: 54% !important;
  }

  #three {
    left: 100% !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 615px) {
	#one {
    right: -37% !important;
  }

  #two {
    right: 56% !important;
  }

  #three {
    /* right: -80% !important; */
    left: 95% !important;
  }
}

@media only screen and (min-width: 616px) and (max-width: 650px) {
	#one {
    right: -32% !important;
  }

  #two {
    right: 58% !important;
  }

  #three {
    /* right: -80% !important; */
    left: 93% !important;
  }
}

@media only screen and (min-width: 651px) and (max-width: 700px) {
	#one {
    right: -27% !important;
  }

  #two {
    right: 59% !important;
  }

  #three {
    /* right: -80% !important; */
    left: 88% !important;
  }
}

@media only screen and (min-width: 701px) and (max-width: 750px) {
	#one {
    right: -17% !important;
  }

  #two {
    right: 63% !important;
  }

  #three {
    /* right: -80% !important; */
    left: 82% !important;
  }
}

@media only screen and (min-width: 751px) and (max-width: 800px) {
	#one {
    right: -7% !important;
  }

  #two {
    right: 66% !important;
  }

  #three {
    /* right: -80% !important; */
    left: 75% !important;
  }
}

@media only screen and (min-width: 801px) and (max-width: 900px) {
	#one {
    right: 0 !important;
  }

  #two {
    right: 68% !important;
  }

  #three {
    /* right: -80% !important; */
    left: 70% !important;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1000px) {
	#one {
    right: 0 !important;
  }

  #two {
    right: 65% !important;
  }

  #three {
    /* right: -80% !important; */
    left: 66% !important;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1100px) {
	#one {
    right: 0 !important;
  }

  #two {
    right: 64% !important;
  }

  #three {
    /* right: -80% !important; */
    left: 65% !important;
  }
}

@media only screen and (min-width: 1101px) and (max-width: 1200px) {
	#one {
    right: 0 !important;
  }

  #two {
    right: 63% !important;
  }

  #three {
    /* right: -80% !important; */
    left: 64% !important;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1300px) {
	#one {
    right: 0 !important;
  }

  #two {
    right: 63% !important;
  }

  #three {
    /* right: -80% !important; */
    left: 63% !important;
  }
}

@media only screen and (min-width: 1301px) and (max-width: 1500px) {
	#one {
    right: 0 !important;
  }

  #two {
    right: 60% !important;
  }

  #three {
    /* right: -80% !important; */
    left: 61% !important;
  }
}

@media only screen and (min-width: 1501px) and (max-width: 1600px) {
	#one {
    right: 0 !important;
  }

  #two {
    right: 60% !important;
  }

  #three {
    /* right: -80% !important; */
    left: 60% !important;
  }
}

@media only screen and (min-width: 1601px) {
  #one {
    right: 0 !important;
  }

  #two {
    right: 59% !important;
  }

  #three {
    /* right: -80% !important; */
    left: 60% !important;
  }
}

.card-sm {
  background: #282520;
  padding: 15px;
  margin-bottom: 15px;
}
.MuiTypography-h6{
  color: white!important;
}
.MuiTypography-body2{
  color: white!important;
}
.backstory img{
  width: 100%;
  border-radius: 10px;
}
.backstory{
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: relative;
  margin-inline: auto;
}
.backstory div{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 45%;
}
.backstory a, .flex-nav-links a{
  color: #FFAA00;
  font-size: 30px;
  margin-bottom: 50px;
}
.flex-nav-links a{
  text-decoration: none;
}
.flex-nav-links {
  gap : 50px;
  display: flex;
  justify-content: center;
}
.flexteam{
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  position: relative;
  width: 100%;
}
.team-member{
  width: 28%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}
.team-member img{
  width: 200px;
  height: 200px;
  object-fit: cover;
  min-width: 200px;
  min-height: 200px;
  border-radius: 100%;
}
.team-member a{
  color: #FFAA00;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
}
.team-member h2{
  color: #FFAA00;
  font-size: 16px;
  margin: 5px 0;
}
.team-member p{
  text-align: center;
}
.story{
  width: 100%;
  margin-inline: auto;
  margin-top: 100px;
  margin-bottom: 50px;
}
.story h2{
  color: #FFAA00;
  font-size: 30px;
  margin-bottom: 20px;
}
.story img{
  width: 75%;
  border-radius: 10px;
  margin: 10px 0;
}
*{
  scroll-behavior: smooth!important;
}
@media(max-width: 1000px){
  .backstory{
    flex-direction: column;
    gap: 50px;
  }
  .story img{
    width: 100%;
  }
  .flex-nav-links a{
    font-size: 20px;
  }
  .story{
    margin-top: 30px;
  }
  .backstory div{
    width: 100%;
  }
  .flexteam{
    flex-direction: column;
    gap: 30px;
  }
  .team-member{
    width: 100%;
  }
}